import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/service/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CabsRequestService {
  currentFormIndex = new BehaviorSubject(null);
  formSchemeType = new BehaviorSubject(null);
  requestDetailsData = new BehaviorSubject(null);

  constructor(private apiService: ApiService) {}
  getMainCabSchemes(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_main_scheme`, model)
      .pipe(catchError(this.handleError));
  }
  getMainSchemes() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_main_scheme`)
      .pipe(catchError(this.handleError));
  }
  getAllMainSchemes() {
    return this.apiService
      .get(`${environment.apiUrl}/api/system/get_all_main_scheme`)
      .pipe(catchError(this.handleError));
  }
  loadAccreditedInfo(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/load_accredited_info`, model)
      .pipe(catchError(this.handleError));
  }
  getSchemeSector(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/get_scope_assessor`, model)
      .pipe(catchError(this.handleError));
  }
  getSchemeSectorByRequestType(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_schema_scopes`, model)
      .pipe(catchError(this.handleError));
  }
  getSchemeApplication(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/get_scheme_application_form`, model)
      .pipe(catchError(this.handleError));
  }
  upload_file(files, type) {
    const formData = new FormData();
    if (files && files.length) {
      files.forEach(file => {
        formData.append('file', file, file.name);
      });
      formData.append('type', type);
    }
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/upload_file`, formData)
      .pipe(catchError(this.handleError));
  }
  saveRecord(model) {
    return this.apiService
      .post(`${environment.apiUrl}/api/system/cab/add_new_request`, model)
      .pipe(catchError(this.handleError));
  }
  addAccreditationCertificate(modal, enAaccCertificate, arAaccCertificate, accScopes, editMode = false) {
    const formData = new FormData();
    enAaccCertificate.forEach(file => {
      if (!file.path){
        formData.append('certificate_file', file, file.name);
      }
    });
    arAaccCertificate.forEach(file => {
      if (!file.path){
        formData.append('certificate_ar_file', file, file.name);
      }
    });
    accScopes.forEach(file => {
      if (!file.path){
        formData.append('scopes_file', file, file.name);
      }
    });

    for (const prop in modal) {
      if (prop === 'scopes') {
        formData.append(prop, JSON.stringify(modal[prop]));
      } else {
        formData.append(prop, modal[prop]);
      }
    }

    if (editMode) {
      return this.apiService
      .post(`${environment.apiUrl}/api/system/edit_accredited_info`, formData)
      .pipe(catchError(this.handleError));
    } else {
      return this.apiService
        .post(`${environment.apiUrl}/api/system/add_accreditation_certificate`, formData)
        .pipe(catchError(this.handleError));
    }
  }

  handleError(error) {
    return throwError(error);
  }
}
